import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { Card, CardBody, Typography, Button } from "@material-tailwind/react";
import { useSelector } from "react-redux";

export default function Designer() {
  const { item } = useSelector((state) => state.item);
  const projeler = item.projeler;
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6 p-4 ">
      <Swiper
        cssMode={true}
        navigation={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
      >
        {projeler.map((item, key) => {
          const image = item.images;
          return (
            <SwiperSlide key={key}>
              <Card className="w-[75%] max-w-[full] card-container black-foto rounded-2xl ">
                <img
                  src={`https://designer.alayadi.site/project_imgs/${image[0].photo}`}
                  alt=""
                  className="rounded-t-2xl"
                />
                <CardBody>
                  <Typography
                    variant="h5"
                    color="blue-gray"
                    className="mb-2 font-tajawal text-white text-center"
                  >
                    {item.title}
                  </Typography>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                    className="font-tajawal text-white text-center"
                  ></p>
                  <Button className="font-tajawal bg-[#740f2d] mt-1" fullWidth>
                    أضغط هنا
                  </Button>
                </CardBody>
              </Card>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <p className="text-white text-center mb-4 font-tajawal p-6">
        يمكنك نشــــر اعمـــال جمهورية الاغا في جميع منصات التواصل الاجتماعي
        ووضع كــــود الخصــــم الخــــاص بك للتعزيز مــن مبيعاتك وأضهار خدمات
        جمهورية الاغا المنتمي اليــــها انـــت
      </p>
    </div>
  );
}
