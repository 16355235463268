import React from "react";

// @material-tailwind/react
import {
  Button,
  Typography,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Card,
  CardBody,
} from "@material-tailwind/react";
import "../../../App.css";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import Reports from "../reports";
import { useSelector } from "react-redux";

function KpiCard({ title }) {
  const { item } = useSelector((state) => state.item);

  return (
    <Card className="shadow-lg shadow-[#600e26] rounded-2xl black-foto border-none ">
      <CardBody className="py-4 px-8">
        <div className="flex justify-between items-center ">
          <Typography as="div" color="white" className="mt-1   ">
            <h1 className="font-bold text-[40px] font-tajawal">
              {item.hesap}
              <span className="text-[25px] ">$</span>
            </h1>
          </Typography>
          <Typography className="font-bold text-lg text-white font-tajawal">
            {title}
          </Typography>
          {/* <div className="flex items-center gap-1">
            {icon}
            <Typography color={color} className="font-medium !text-xs">
              {percentage}
            </Typography>
          </div> */}
        </div>
      </CardBody>
    </Card>
  );
}

const data = [
  {
    title: "الأربــاح",
  },
];

function Home() {
  return (
    <section className="container mx-auto  px-6">
      <div className="mt-6 mb-4 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 items-center md:gap-2.5 gap-4 ">
        {data.map((props, key) => (
          <KpiCard key={key} {...props} />
        ))}
      </div>
      <Reports />
    </section>
  );
}

export default Home;
