import { useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Footer } from "./components/layouts/Footer";
import NavBar from "./components/layouts/Navbar";
import Home from "./components/page/home";
import Designer from "./components/page/designer";
import { Login } from "./components/page/login";
import Code from "./components/page/code";
import "./App.css";
import Img1 from "./components/images/1.png";
import Img2 from "./components/images/2.png";

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <div className="min-h-screen flex flex-col bady ">
      <div className="image-container">
        <img src={Img2} className="left-image" />
        <img src={Img1} className="right-image " />
      </div>
      <BrowserRouter>
        {isLoggedIn && (
          <div className="sticky top-2 p-2 z-50">
            <NavBar onLogin={setIsLoggedIn} />
          </div>
        )}
        <div
          className={`flex-grow ${
            !isLoggedIn ? "flex items-center justify-center" : ""
          }`}
        >
          <Routes>
            {isLoggedIn ? (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/code" element={<Code />} />
                <Route path="/designer" element={<Designer />} />
                <Route path="*" element={<Navigate to="/" />} />
              </>
            ) : (
              <Route
                path="/login"
                element={<Login onLogin={setIsLoggedIn} />}
              />
            )}
            {/* إعادة توجيه إلى صفحة تسجيل الدخول إذا لم يكن المستخدم مسجل الدخول */}
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </div>
        {isLoggedIn && (
          <div className="px-2 text-center">
            <Footer />
          </div>
        )}
      </BrowserRouter>
    </div>
  );
}
