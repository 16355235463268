import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Logo from "../images/logo1.png";
import { Link, Navigate } from "react-router-dom";
import {
  PresentationChartBarIcon,
  FunnelIcon,
  QrCodeIcon,
  PowerIcon,
} from "@heroicons/react/24/solid";

function NavList({ onLogin, setOpenNav, openNav }) {
  return (
    <ul
      dir="rtl"
      className="my-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6"
    >
      <Typography
        as="li"
        variant="small"
        color="white"
        className="p-1 font-medium"
      >
        <Link
          onClick={() => {
            setOpenNav(!openNav);
          }}
          to={"/"}
          className="flex items-center hover:text-[#171f33] transition-colors font-tajawal"
        >
          <PresentationChartBarIcon className="h-5 w-5 ml-2" /> الرئيسية
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="white"
        className="p-1 font-medium"
      >
        <Link
          onClick={() => {
            setOpenNav(!openNav);
          }}
          to={"/code"}
          className="flex items-center hover:text-[#171f33] transition-colors font-tajawal"
        >
          <QrCodeIcon className="h-5 w-5 ml-2" />
          الكود
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="white"
        className="p-1 font-medium"
      >
        <Link
          onClick={() => {
            setOpenNav(!openNav);
          }}
          to={"/designer"}
          className="flex items-center hover:text-[#171f33] transition-colors font-tajawal"
        >
          <FunnelIcon className="h-5 w-5 ml-2" />
          التصميم
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="white"
        className="p-1 font-medium"
      >
        <Link
          onClick={() => {
            onLogin(false);
          }}
          className="flex items-center hover:text-[#171f33] transition-colors font-tajawal"
        >
          <PowerIcon className="h-5 w-5 ml-2" />
          تسجيل الخروج
        </Link>
      </Typography>
    </ul>
  );
}

export default function NavBar({ onLogin }) {
  const [openNav, setOpenNav] = React.useState(false);

  const handleWindowResize = () =>
    window.innerWidth >= 960 && setOpenNav(false);

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Navbar className="mx-auto max-w-screen-xl px-6 py-3 bg-[#740f2d] border-none shadow-lg shadow-[#600e26]">
      <div className="flex items-center justify-between text-blue-gray-900">
        <Typography variant="h6" className="mr-4 cursor-pointer py-1.5">
          <img src={Logo} alt="" style={{ width: "40%", height: "100%" }} />
        </Typography>
        <div className="hidden lg:block">
          <NavList
            onLogin={onLogin}
            setOpenNav={setOpenNav}
            openNav={openNav}
          />
        </div>
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6 text-white" strokeWidth={2} />
          ) : (
            <Bars3Icon className="h-6 w-6 text-white" strokeWidth={2} />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <NavList onLogin={onLogin} openNav={openNav} setOpenNav={setOpenNav} />{" "}
        {/* Pass the onLogin prop here */}
      </Collapse>
    </Navbar>
  );
}
